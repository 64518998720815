import React from 'react'
import * as styles from './header-link.module.scss'

import { Link } from 'gatsby'
import { useLocalPath } from '../../../../hooks'

const HeaderLink = ({
    data
}) => {
    if (data.target) {
        return (
            <div className={styles.link}>
                <Link to={useLocalPath(data.target.slug)}>
                    {data.title}
                </Link>
            </div>
        )
    } else if (data.slug) {
        return (
            <div className={styles.link}>
                <Link to={useLocalPath(data.slug)}>
                    {data.title}
                </Link>
            </div>
        )
    } else if (data.externalLink) {
        return (
            <div className={styles.link}>
                <a
                    href={data.externalLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Link opens in a new window"
                    title={data.title}
                >
                    {data.title}
                </a>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}

export default HeaderLink