/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import Layout from "./src/components/Layout/Layout";
import Scripts from "./src/components/Layout/Scripts";
import { LocaleContext } from "./src/utils/context";

export const wrapPageElement = ({
    element,
    props: {
        pageContext: {
            node_locale
        }
    }
}) => {
    return (
        <LocaleContext.Provider value={{ locale: node_locale }}>
            <Scripts />
            <Layout>{element}</Layout>
        </LocaleContext.Provider>
    )
}