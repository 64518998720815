import { graphql, useStaticQuery } from "gatsby";

export const useSiteQuery = () => {
    const {
        allContentfulGlobalSite: { edges }
    } = useStaticQuery(
        graphql`
            query MyQuery {
                allContentfulGlobalSite(filter: {contentful_id: {eq: "A1CLUdE0lZo9v3Xkp4qRc"}}) {
                    edges {
                        node {
                            id
                            contentful_id
                            node_locale
                            logo {
                                gatsbyImageData
                                title
                            }
                            headerLeft {
                                ...componentLink
                            }
                            headerRight {
                                ...componentLink
                            }
                            footerMenu {
                                ... on ContentfulComponentMenuGroup {
                                    childrenItems {
                                        ...componentLink
                                    }
                                }
                            }
                            defaultSeoImage {
                                file {
                                    url
                                }
                            }
                            sustainabilityAccordionImage{
                                gatsbyImageData
                            } 
                        }    
                    }
                }
            }
        `
    )
    const localData = edges.filter(({ node }) => node.node_locale === 'ja-JP')
    const data = localData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return data
}