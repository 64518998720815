import React, { useEffect } from 'react'
import { useLocation } from '@reach/router';

const Scripts = () => {

    const location = useLocation()

    const events = [
        'keypress', 'mousemove', 'touchstart', 'touchmove', 'click', 'scroll'
    ]

    const cookieScriptInit = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "usercentrics-cmp")
        script.setAttribute('src', "https://app.usercentrics.eu/browser-ui/latest/loader.js")
        script.setAttribute('data-settings-id', "tDNr_BXUn")
        script.setAttribute('data-language', 'ja')
        script.setAttribute('async', 'true')
        document.head.appendChild(script)
    }

    const gtm = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "script-gtm")
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WCBFC6T');`
        document.head.appendChild(script)

        const noScript = document.createElement('noscript')
        noScript.setAttribute('id', "noscript-gtm")
        noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WCBFC6T" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        document.body.appendChild(noScript)
    }



    // const zendesk = () => {
    //     const script = document.createElement('script')
    //     script.setAttribute('id', "ze-snippet")
    //     script.setAttribute('src', "https://static.zdassets.com/ekr/snippet.js?key=24e5119a-6f4e-4671-b1e3-cb5f0226892f")
    //     script.setAttribute('async', 'true')
    //     document.head.appendChild(script)
    // }

    const handleEvent = () => {
        cookieScriptInit()
        if(!document.getElementById("script-gtm")) gtm()

        events.forEach((event) => window.removeEventListener(event, handleEvent))
    }

    useEffect(() => {
        events.forEach((event) => window.addEventListener(event, handleEvent))
    }, [location])

    setTimeout(() => {
        handleEvent()
    }, 6000)

    return (
        <></>
    )
}

export default Scripts