import React from 'react'
import * as styles from './header.module.scss';

import { useLocalPath, useSiteQuery } from '../../../hooks';
import HeaderLink from './HeaderLink/HeaderLink';
import { GatsbyImage } from 'gatsby-plugin-image';

import Account from '../../../static/account.svg';
import Search from '../../../static/search-icon.svg';
import Cart from '../../../static/shopping-cart.svg';
import MobileNav from './MobileNav/MobileNav';
import { Link } from 'gatsby';

const Header = () => {
    const {
        logo,
        headerLeft,
        headerRight
    } = useSiteQuery()

    return (
        <header>
            <MobileNav />
            <div className={`${styles.container}`}>
                <nav className={`${styles.navBar}`}>
                    <div className={`${styles.headerNav}`}>
                        {
                            headerLeft.map((item, index) => {
                                return (item.slug || item.externalLink || item.target) && (
                                    <div key={index}>
                                        <HeaderLink data={item} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.logo}>
                        <Link to={useLocalPath('')}>
                            <GatsbyImage objectFit='contain' image={logo.gatsbyImageData} alt="Lipton Japan Brand Logo" />
                        </Link>
                    </div>
                    <div className={`${styles.headerNav}`}>
                        {
                            headerRight.map((item, index) => {
                                return (item.slug || item.externalLink || item.target) && (
                                    <div key={index}>
                                        <HeaderLink data={item} />
                                    </div>
                                )
                            })
                        }
                        {/* <div className={styles.iconContainer}>
                            <div className={styles.icon}><Search /></div>
                            <div className={styles.icon}><Account /></div>
                            <div className={styles.icon}><Cart /></div>
                        </div> */}
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header