import React, { useEffect, useState } from 'react'
import "../../styles/main.scss"

import Footer from './Footer/Footer'
import Header from './Header/Header'

import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

const Layout = ({
    children
}) => {
    const {
        pathname
    } = useLocation()
    const [overflow, setOverflow] = useState(true)
    const {
        allContentfulPageProductCategory: {
            nodes
        }
    } = useStaticQuery(graphql`
        query {
            allContentfulPageProductCategory {
                nodes {
                    slug
                }
            }
        }
  `)
    useEffect(() => {
        if (nodes.find(({ slug }) => pathname.includes(slug))) {
            setOverflow(false)
        }
        return () => {
            setOverflow(true)
        }
    }, [pathname])

    // useEffect(() => {
    //     window.zESettings = {
    //         webWidget: {
    //             chat: { suppress: true },
    //             helpCenter: { suppress: true },
    //             talk: { suppress: true },
    //             answerBot: { suppress: true },
    //         }
    //     };

    //     window.zESettings = {
    //         webWidget: {
    //             chat: {
    //                 departments: { select: 'france' }
    //             }
    //         }
    //     };
    // }, [])

    return (
        <div>
            <Header />
            <main className={`main ${overflow ? 'overflow' : ''}`}>
                <div className='page'>{children}</div>
            </main>
            <Footer />
        </div>
    )
}

export default Layout