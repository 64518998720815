import React from "react"
import { useStaticQuery, graphql } from "gatsby"
export const useSiteDictionary = () => {
    const {
        allContentfulGlobalDictionary: { edges }
    } = useStaticQuery(
        graphql`
            query useSiteDictionary {
                allContentfulGlobalDictionary(
                    filter: {contentful_id: {eq: "26duGhXG5905YmrEV3uBlu"}}
                  ) {
                    edges {
                        node {
                            node_locale
                            contentful_id
                            buyNowButtonText
                            brandPageButton
                            rakutenButton
                            relatedProductsText
                            seeMoreText
                            yahooButton
                            ingredientsText
                            seeMoreProductsButton
                            preparationTime
                            thingsNecessary
                            methodText: method
                            minutesUnits
                            servingsUnits
                            recipeSuggestionsTitle
                            ymMaterial
                            ymHowToMake
                            overviewText
                            commentText
                            contactUsContentPart1 {
                                raw
                              }
                              contactUsContentPart2 {
                                raw
                              }
                              contactUsButtonText
                        }
                    }
                }
            } 
        `
    )
    const localData = edges.filter(({ node }) => node.node_locale === 'ja-JP')
    const data = localData.reduce((acc, val) => ({ ...acc, ...val.node }), {})
    return data
}