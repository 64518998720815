import React,{useState} from 'react'
import * as styles from './footer.module.scss';

import { useSiteQuery, useLocalPath } from '../../../hooks';

import CookiePopup from "../CookiePopup/CookiePopup"

import { Link } from 'gatsby'
const Footer = () => {
    const {
        footerMenu
    } = useSiteQuery()

    const [privacyOpen, setPrivacyOpen] = useState(false)



    return (
        <footer className={styles.footer}>
            <div className={styles.items}>
                {
                    footerMenu.map(({ childrenItems }, index) => (
                        <div key={index} className={styles.list}>
                            {
                                childrenItems.map(({ title, slug, externalLink, target }, index) => {
                                    if (target) {
                                        return (
                                            <div className={styles.item} key={index}>
                                                <Link to={useLocalPath(target.slug)}>{title}</Link>
                                            </div>
                                        )
                                    } else if (slug) {
                                        return (
                                            <div className={styles.item} key={index}>
                                                <Link to={useLocalPath(slug)}>{title}</Link>
                                            </div>
                                        )
                                    }
                                    else if (!externalLink) {
                                        return (
                                            <div className={styles.item} key={index}>
                                                  <p className={styles.privacyPopupLink} onClick={e => { setPrivacyOpen(true) }}>Cookieの詳細</p>
                    <div className={`${styles.wrapper} ${privacyOpen ? styles.privacyOpen : ''}`} >
                        {
                            privacyOpen
                                ? <CookiePopup setOpen={setPrivacyOpen} />
                                : null}
                        {/* <CookiePopup setOpen={setPrivacyOpen} /> */}
                    </div>
                                            </div>
                                        )
                                    }
                                    
                                    
                                    
                                    else {
                                        return (
                                            <div className={styles.item} key={index}>
                                                <a
                                                    href={externalLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    aria-label="Link opens in a new window"
                                                    title="Link opens in a new window"
                                                >{title}</a>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    ))
                }
            </div>
            <div className={styles.icons}></div>
        </footer>
    )
}

export default Footer