import React from 'react'
import * as styles from './mobile-nav.module.scss'

import { useLocale, useSiteQuery } from '../../../../hooks'
import { GatsbyImage } from 'gatsby-plugin-image'
import Account from '../../../../static/account.svg';
import Search from '../../../../static/search-icon.svg';
import Cart from '../../../../static/shopping-cart.svg';
import { mapSiteName } from '../../../../utils/mapping';
import HeaderLink from '../HeaderLink/HeaderLink';

const MobileNav = () => {
    const {
        logo,
        headerLeft,
        headerRight
    } = useSiteQuery()

    const locale = useLocale()

    return (
        <div className={styles.container}>
            <GatsbyImage
                image={logo.gatsbyImageData}
                alt="Lipton Japan Brand Logo"
                objectFit="contain"
                objectPosition="left"
                className={styles.logoImage}
            />
            <div className={styles.empty}>&nbsp;</div>
            <div className={styles.iconContainer}>
                {/* <div className={styles.icon}><Search /></div> */}
                {/* <div className={styles.icon}><Account className={styles.accountIcon} /></div>
                <div className={styles.icon}><Cart /></div> */}
                {/* <div className={`${styles.icon} ${styles.menuIcon}`}> */}
                <div className={`${styles.icon}`}>
                    <input
                        type="checkbox"
                        name=""
                        id="naviToggle"
                        hidden
                        className={styles.navigation__checkbox}
                    />
                    <label
                        htmlFor="naviToggle"
                        className={styles.navigation__button}
                    >
                        <span className={styles.navigation__icon}>&nbsp;</span>
                        <span style={{ display: 'none' }}>Checkbox for Side Menu</span>
                    </label>
                    <ul className={styles.navContainer}>
                        {
                            headerLeft.map((item, index) => (
                                <li key={index} className={styles.linkItem}>
                                    <HeaderLink data={item} />
                                </li>
                            ))
                        }
                        {
                            headerRight.map((item, index) => (
                                <li key={index} className={styles.linkItem}>
                                    <HeaderLink data={item} />
                                </li>
                            ))
                        }
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default MobileNav