exports.components = {
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-contact-tsx": () => import("./../../../src/templates/PageContact.tsx" /* webpackChunkName: "component---src-templates-page-contact-tsx" */),
  "component---src-templates-page-not-found-tsx": () => import("./../../../src/templates/PageNotFound.tsx" /* webpackChunkName: "component---src-templates-page-not-found-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/ProductCategory.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-recipe-category-tsx": () => import("./../../../src/templates/RecipeCategory.tsx" /* webpackChunkName: "component---src-templates-recipe-category-tsx" */),
  "component---src-templates-recipe-landing-tsx": () => import("./../../../src/templates/RecipeLanding.tsx" /* webpackChunkName: "component---src-templates-recipe-landing-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/Recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../../src/templates/Template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}

